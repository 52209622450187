import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import { Grid } from '@material-ui/core'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'

import {
    GatsbyImage,
    getImage,
    StaticImage,
    withArtDirection,
} from 'gatsby-plugin-image'

import Link from '../../components/gatsby-link'

import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

import './styles.css'

// modules styles
import 'swiper/components/pagination/pagination.min.css'

// swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'

// swiper modules you want to use
import SwiperCore, { Pagination, Navigation } from 'swiper'

function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    }
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        // backgroundColor: theme.palette.background.paper,
    },
    cardRoot: {
        maxWidth: 345,
        marginBottom: '15px',
    },
    date: {
        fontSize: '17px',
        fontWeight: 400,
        color: '#0F051899',
        fontFamily: 'Mulish',
        textAlign: 'right',
    },
    tagContainer: {
        display: 'inline-block',
        padding: '0px 10px',
        borderRadius: '16px',
        background:
            'linear-gradient(152.51deg, #14FFD7 -64.25%, #75CCBD 105.81%)',
    },

    author: {
        fontSize: '15px',
        fontWeight: 700,
        lineHeight: '24px',
        color: '#FFFFFF',
        textAlign: 'center',
        borderRadius: '16px',
        fontFamily: 'IBM Plex Sans',
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
            lineHeight: '18px',
        },
    },
    postTitle: {
        color: '#0F0518',
        fontFamily: 'IBM Plex Mono',
    },
    timeToRead: {
        fontSize: '17px',
        fontWeight: 400,
        color: '#0F051899',
        fontFamily: 'Mulish',
        textAlign: 'left',
        marginLeft: '5px',
    },
    ttrContainer: {
        marginTop: '15px',
    },
    indicator: {
        backgroundColor: '#F45F00',
    },
    mainTitle: {
        fontWeight: 700,
        textShadow: '1px 1px 1px grey',
        fontSize: '54px',
        color: '#FFFFFF',
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
        },
    },
    text: {
        fontWeight: 400,
        fontSize: '24px',
        textShadow: '1px 1px 1px grey',
        color: '#FFFFFF',
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
        },
    },
    link: {
        color: 'inherit',
        textDecoration: 'none',
    },
    breadcrumbs: {
        fontSize: '19px',
        fontFamily: 'IBM Plex Sans',
        fontWeight: 400,
        lineHeight: 1.5,
        color: 'rgba(0, 0, 0, 0.54)',
        textDecoration: 'none',
    },
    imageTextContainer: {
        position: 'absolute',
        top: 140,
        left: 100,
        width: '50%',
        [theme.breakpoints.down('sm')]: {
            top: 25,
            left: 25,
            width: '75%',
        },
    },
    sectionTitles: {
        fontSize: '36px',
        fontWeigh: 700,
        fontFamily: 'IBM Plex Mono',
        [theme.breakpoints.down('sm')]: {
            fontSize: '20px',
        },
    },
}))

const link = (name) => (name === 'index' ? '/' : `/${name}`)

const BlogIndex = (props) => {
    const classes = useStyles()
    const data = props.data.allContentfulPost.edges
    const mainData = props.data.contentfulComponentBlogIndex1
    const [value, setValue] = React.useState(0)

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const [newArray, setNewArray] = useState([])
    const [duplicatesArray, setDuplicatesArray] = useState([])
    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
        const tempArray = data
            .filter((element) => element.node.blogTags !== null)
            .reduce((acc, option) => {
                const tag = option.node.blogTags
                if (!acc[tag]) {
                    acc[tag] = []
                }
                acc[tag].push(option)
                return acc
            }, {})

        const uniqueTags = [...new Set(Object.keys(tempArray))]
        const groupedArray = uniqueTags.map((tag) => tempArray[tag])
        const duplicates = [].concat(...groupedArray)

        setNewArray(groupedArray)
        setDuplicatesArray(duplicates)
    }, [data])

    const groups = duplicatesArray.reduce((acc, option) => {
        const tag = option.node.blogTags
        if (!acc[tag]) {
            acc[tag] = []
        }
        acc[tag].push(option)
        return acc
    }, {})

    function handleClick(event) {
        // event.preventDefault()
        console.info('You clicked a breadcrumb.')
    }

    const image = withArtDirection(getImage(mainData.image), [
        {
            media: '(max-width: 600px)',
            image: getImage(mainData.mobileImage),
        },
    ])

    SwiperCore.use([Navigation, Pagination])
    // const isMobile = window.innerWidth < 768

    function PopularSwiper() {
        return (
            <Swiper
                breakpoints={{
                    // when window width is >= 640px
                    320: {
                        width: 320,
                        slidesPerView: 1,
                    },
                    768: {
                        slidesPerView: 3,
                    },
                }}
                spaceBetween={30}
                pagination={{
                    type: 'fraction',
                }}
                navigation={true}
                modules={[Pagination, Navigation]}
                className="mySwiper"
            >
                {mainData.popularPosts.map((option) => {
                    const images = getImage(option.image)
                    return (
                        <SwiperSlide>
                            <Card>
                                <Link
                                    className={classes.link}
                                    to={link('blog/' + option.slug)}
                                >
                                    <CardActionArea>
                                        <GatsbyImage
                                            image={images}
                                            alt={option.image.title}
                                        />
                                        <CardContent>
                                            <Grid container>
                                                <Grid item xs={6}>
                                                    <Box
                                                        className={
                                                            classes.tagContainer
                                                        }
                                                    >
                                                        <Typography
                                                            className={
                                                                classes.author
                                                            }
                                                        >
                                                            {option.blogTags}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    {' '}
                                                    <Typography
                                                        gutterBottom
                                                        className={classes.date}
                                                    >
                                                        {option.datePosted}
                                                    </Typography>
                                                </Grid>
                                            </Grid>

                                            <Typography
                                                gutterBottom
                                                variant="h5"
                                                component="h2"
                                                className={classes.postTitle}
                                            >
                                                {option.title}
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                color="textSecondary"
                                                component="p"
                                            >
                                                {option.subtitle}
                                            </Typography>
                                            <Grid
                                                container
                                                className={classes.ttrContainer}
                                            >
                                                <Grid item xs={1}>
                                                    {' '}
                                                    <StaticImage
                                                        src="../../assets/clock.png"
                                                        alt="clock"
                                                    />
                                                </Grid>
                                                <Grid item xs={5}>
                                                    {' '}
                                                    <Typography
                                                        gutterBottom
                                                        className={
                                                            classes.timeToRead
                                                        }
                                                    >
                                                        {option.timeToRead}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </CardActionArea>
                                </Link>
                            </Card>
                        </SwiperSlide>
                    )
                })}
            </Swiper>
        )
    }

    return (
        <Container maxWidth="lg">
            <Breadcrumbs
                aria-label="breadcrumb"
                onClick={handleClick}
                style={{ fontSize: '19px', marginBottom: '30px' }}
            >
                <Link color="inherit" href="/" className={classes.breadcrumbs}>
                    Home
                </Link>
                <Typography color="textPrimary" style={{ fontSize: '19px' }}>
                    Blog
                </Typography>
            </Breadcrumbs>

            <div style={{ position: 'relative' }}>
                <GatsbyImage
                    image={image}
                    alt={mainData.title}
                    style={{ marginBottom: '30px', borderRadius: '14px' }}
                />
                <div className={classes.imageTextContainer}>
                    <Typography className={classes.mainTitle}>
                        {mainData.mainTitle}
                    </Typography>
                    <Typography className={classes.text}>
                        {mainData.text}
                    </Typography>
                </div>
            </div>
            <Typography className={classes.sectionTitles}>Popular: </Typography>
            <PopularSwiper />
            <div className={classes.root}>
                <Typography
                    className={classes.sectionTitles}
                    style={{ textAlign: 'center', margin: '25px 0' }}
                >
                    {' '}
                    Articles
                </Typography>
                <AppBar
                    position="static"
                    color="default"
                    style={{
                        borderRadius: '4px',
                        backgroundColor: '#ffffff',
                        boxShadow: 'none',
                        border: '1px solid rgb(185 185 185 / 60%)',
                    }}
                >
                    {' '}
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="secondary"
                        variant="scrollable"
                        classes={{
                            indicator: classes.indicator,
                        }}
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                    >
                        {newArray.map((option, index) => {
                            return (
                                <Tab
                                    label={`${option[0].node.blogTags} (${option.length})`}
                                    {...a11yProps({ index: index + 1 })}
                                />
                            )
                        })}
                        {/* ALL POSTS TAB */}
                        <Tab
                            label={`All (${data.length})`}
                            {...a11yProps(newArray.length)}
                        />
                    </Tabs>
                </AppBar>
                {/* TABPANEL FOR ALL POSTS */}
                <TabPanel value={value} index={newArray.length}>
                    <div className={classes.root}>
                        <Grid container>
                            {data
                                .sort((a, b) =>
                                    a.node.sortingDate > b.node.sortingDate
                                        ? -1
                                        : 1
                                )
                                .map((option) => {
                                    const images = getImage(option.node.image)
                                    const hasImage = Boolean(option.node.image)
                                    return (
                                        <Grid item xs={12} md={4}>
                                            <Card className={classes.cardRoot}>
                                                <CardActionArea>
                                                    <Link
                                                        className={classes.link}
                                                        to={link(
                                                            'blog/' +
                                                                option.node.slug
                                                        )}
                                                    >
                                                        {hasImage && (
                                                            <GatsbyImage
                                                                image={images}
                                                                alt={
                                                                    option.node
                                                                        .image
                                                                        .title
                                                                }
                                                            />
                                                        )}
                                                        <CardContent>
                                                            <Grid container>
                                                                <Grid xs={6}>
                                                                    <Box
                                                                        className={
                                                                            classes.tagContainer
                                                                        }
                                                                    >
                                                                        <Typography
                                                                            className={
                                                                                classes.author
                                                                            }
                                                                        >
                                                                            {
                                                                                option
                                                                                    .node
                                                                                    .blogTags
                                                                            }
                                                                        </Typography>
                                                                    </Box>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={6}
                                                                >
                                                                    {' '}
                                                                    <Typography
                                                                        gutterBottom
                                                                        className={
                                                                            classes.date
                                                                        }
                                                                    >
                                                                        {
                                                                            option
                                                                                .node
                                                                                .datePosted
                                                                        }
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>

                                                            <Typography
                                                                gutterBottom
                                                                variant="h5"
                                                                component="h2"
                                                                className={
                                                                    classes.postTitle
                                                                }
                                                            >
                                                                {
                                                                    option.node
                                                                        .title
                                                                }
                                                            </Typography>
                                                            <Typography
                                                                variant="body2"
                                                                color="textSecondary"
                                                                component="p"
                                                            >
                                                                {
                                                                    option.node
                                                                        .subtitle
                                                                }
                                                            </Typography>
                                                            <Grid
                                                                container
                                                                className={
                                                                    classes.ttrContainer
                                                                }
                                                            >
                                                                <Grid
                                                                    item
                                                                    xs={1}
                                                                >
                                                                    {' '}
                                                                    <StaticImage
                                                                        src="../../assets/clock.png"
                                                                        alt="clock"
                                                                    />
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={5}
                                                                >
                                                                    {' '}
                                                                    <Typography
                                                                        gutterBottom
                                                                        className={
                                                                            classes.timeToRead
                                                                        }
                                                                    >
                                                                        {
                                                                            option
                                                                                .node
                                                                                .timeToRead
                                                                        }
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </CardContent>
                                                    </Link>
                                                </CardActionArea>
                                            </Card>
                                        </Grid>
                                    )
                                })}
                        </Grid>
                    </div>
                </TabPanel>
                {/* TABPANELS minus ALL */}
                {Object.values(groups).map((group, index) => (
                    <TabPanel value={value} index={index}>
                        <div className={classes.root}>
                            <Grid container>
                                {group
                                    .sort((a, b) =>
                                        a.node.sortingDate > b.node.sortingDate
                                            ? -1
                                            : 1
                                    )
                                    .map((option) => {
                                        const images = getImage(
                                            option.node.image
                                        )
                                        return (
                                            <Grid item xs={12} md={4}>
                                                <Card
                                                    className={classes.cardRoot}
                                                >
                                                    <CardActionArea>
                                                        <Link
                                                            className={
                                                                classes.link
                                                            }
                                                            to={link(
                                                                'blog/' +
                                                                    option.node
                                                                        .slug
                                                            )}
                                                        >
                                                            <GatsbyImage
                                                                image={images}
                                                                alt={
                                                                    option.node
                                                                        .image
                                                                        .title
                                                                }
                                                            />
                                                            <CardContent>
                                                                <Grid container>
                                                                    <Grid
                                                                        item
                                                                        xs={6}
                                                                    >
                                                                        <Box
                                                                            className={
                                                                                classes.tagContainer
                                                                            }
                                                                        >
                                                                            <Typography
                                                                                className={
                                                                                    classes.author
                                                                                }
                                                                            >
                                                                                {
                                                                                    option
                                                                                        .node
                                                                                        .blogTags
                                                                                }
                                                                            </Typography>
                                                                        </Box>
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={6}
                                                                    >
                                                                        {' '}
                                                                        <Typography
                                                                            gutterBottom
                                                                            className={
                                                                                classes.date
                                                                            }
                                                                        >
                                                                            {
                                                                                option
                                                                                    .node
                                                                                    .datePosted
                                                                            }
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>

                                                                <Typography
                                                                    gutterBottom
                                                                    variant="h5"
                                                                    component="h2"
                                                                    className={
                                                                        classes.postTitle
                                                                    }
                                                                >
                                                                    {
                                                                        option
                                                                            .node
                                                                            .title
                                                                    }
                                                                </Typography>
                                                                <Typography
                                                                    variant="body2"
                                                                    color="textSecondary"
                                                                    component="p"
                                                                >
                                                                    {
                                                                        option
                                                                            .node
                                                                            .subtitle
                                                                    }
                                                                </Typography>
                                                                <Grid
                                                                    container
                                                                    className={
                                                                        classes.ttrContainer
                                                                    }
                                                                >
                                                                    <Grid
                                                                        item
                                                                        xs={1}
                                                                    >
                                                                        {' '}
                                                                        <StaticImage
                                                                            src="../../assets/clock.png"
                                                                            alt="clock"
                                                                        />
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={5}
                                                                    >
                                                                        {' '}
                                                                        <Typography
                                                                            gutterBottom
                                                                            className={
                                                                                classes.timeToRead
                                                                            }
                                                                        >
                                                                            {
                                                                                option
                                                                                    .node
                                                                                    .timeToRead
                                                                            }
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </CardContent>
                                                        </Link>
                                                    </CardActionArea>
                                                </Card>
                                            </Grid>
                                        )
                                    })}
                            </Grid>
                        </div>
                    </TabPanel>
                ))}
            </div>
        </Container>
    )
}
export default BlogIndex
